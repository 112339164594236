import _get from 'lodash/get'

const config = {
	app_api_url: _get(process.env, 'VUE_APP_API_URL', ''),
    app_idle_timeout: _get(process.env, 'VUE_APP_IDLE_TIMEOUT', '17'),
    app_default_timezone: _get(process.env, 'VUE_APP_DEFAULT_TIMEZONE', 'Australia/Canberra'),
    app_version: _get(process.env, 'VUE_APP_VERSION', '1.0.0'),
    app_title: _get(process.env, 'VUE_APP_TITLE', ''),
    app_env: _get(process.env, 'VUE_APP_ENV', 'production'),
    app_name: _get(process.env, 'VUE_APP_NAME', 'Digitalhub'),
    app_gps_live_tracker: _get(process.env, 'VUE_APP_GPS_LIVE_TRACKER', ''),
}

export default config;