<template>
  <div class="loader_container" :style="'height:' + (size ? size + 'px':'75px')">
    <img src="../../assets/loading.gif" 
        :style="'height:' + (size ? size + 'px':'75px')+';width:' + (size ? size + 'px':'75px')"
    />
  </div>
</template>
<script>
export default {
  name: "loadingOverlay",
  props:["size"]
};
</script>
<style scoped>
.loader_container {
  width:100%;
  display: flex;
  flex-direction: column;
  padding:20px 0;
}
.loader_container img {
  margin: auto;
  flex-shrink: 0;
}
</style>