const Auth = () => {
  let token = localStorage.getItem("token");
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    throw "Could not decode token";
  }
};

export default Auth;
