import _get from 'lodash/get'
import http from '../../http';

const initialState = () => ({
	configurations: {},
})

export default {
	namespaced: true,
	state: initialState(),
	getters: {
		configurations: (state) => localStorage.getItem('configurations') ? JSON.parse(localStorage.getItem('configurations')) : {},
	},
	mutations: {
		SET_CONFIGURATIONS: (state, { configurations }) => {
            localStorage.removeItem('configurations');
            localStorage.setItem('configurations', JSON.stringify(configurations));
			state.configurations = configurations;
		},
	},
	actions: {
		setConfigurations: async ({ commit }) => {
			try {
				const response = await http.get('/api/settings/all');
                const configurations = _get(response, 'data.configurations');
                commit('SET_CONFIGURATIONS', { configurations });
			} catch (error) {
				throw error;
			}
		},
	},
}