import _get from 'lodash/get'
import http from '../../http';

const initialState = () => ({
	countries: {},
})

export default {
	namespaced: true,
	state: initialState(),
	getters: {
		countries: (state) => localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : {},
	},
	mutations: {
		SET_COUNTRIES: (state, { countries }) => {
            localStorage.removeItem('countries');
            localStorage.setItem('countries', JSON.stringify(countries));
			state.countries = countries;
		},
	},
	actions: {
		setCountries: async ({ commit }) => {
			try {
				const response = await http.get('/api/countries');
                const countries = _get(response, 'data.result.data');
                commit('SET_COUNTRIES', { countries });

                return countries;
			} catch (error) {
				throw error;
			}
		},
	},
}