import Vue from 'vue';
import Router from 'vue-router';

/**
 * Lazy loaded routes
 */
const Login = () => import(/* webpackChunkName: 'login' */ './components/Login.vue');
const OtpVerify = () => import(/* webpackChunkName: 'otp-verify' */ './components/OtpVerify.vue');
const Dashboard = () => import(/* webpackChunkName: 'dashboard' */ './components/pages/Dashboard.vue');
const MailRoom = () => import(/* webpackChunkName: 'mailroom' */ './components/pages/MailRoom.vue');
const Encoding = () => import(/* webpackChunkName: 'encoding' */ './components/pages/Encoding.vue');
const AddressBook = () => import(/* webpackChunkName: 'address-book' */ './components/pages/AddressBook.vue');
const PersonalAddressBook = () => import(/* webpackChunkName: 'personal-address-book' */ './components/pages/PersonalAddressBook.vue');
const PostalTransactionSummary = () => import(/* webpackChunkName: 'postal-transaction-summary' */ './components/pages/PostalTransactionSummary.vue');
const Reports = () => import(/* webpackChunkName: 'reports' */ './components/pages/Reports.vue');
const ReportsMailroom = () => import(/* webpackChunkName: 'reports-mailroom' */ './components/pages/ReportsMailroom.vue');
const CourierBillingReport = () => import(/* webpackChunkName: 'reports-mailroom' */ './components/pages/CourierBillingReport.vue');
const ReportsDelivery = () => import(/* webpackChunkName: "reports-delivery" */ './components/pages/ReportsDelivery.vue');
const ReportsDeliveryPercentage = () => import(/* webpackChunkName: "reports-delivery-percentage" */ './components/pages/ReportsDeliveryPercentage.vue');
const InboundDeliveryReport = () => import(/* webpackChunkName: "inbound-delivery-report" */ './components/pages/InboundDeliveryReport.vue');
const OutboundPostalReport = () => import(/* webpackChunkName: "outbound-postal-report" */ './components/pages/OutboundPostalReport.vue');
const OutboundMarkupReport = () => import(/* webpackChunkName: "outbound-postal-report" */ './components/pages/OutboundMarkupReport.vue');
const ReportsDeliveryPerformance = () => import(/* webpackChunkName: "reports-delivery-performance" */ './components/pages/ReportsDeliveryPerformance.vue');
const ViewPending = () => import(/* webpackChunkName: "view-pending" */ './components/pages/ViewPending.vue');
const ViewCourierPending = () => import(/* webpackChunkName: "view-courier-pending" */ './components/pages/ViewPending.vue?1=2');
const ViewEncodePending = () => import(/* webpackChunkName: "view-encode-pending" */ './components/pages/ViewPending.vue?3=2');
const ViewPostalServices = () => import(/* webpackChunkName: "view-postal-services" */ './components/pages/ViewPostalServices.vue');
const ViewPendingTransaction = () => import(/* webpackChunkName: "view-pending-transaction" */ './components/pages/ViewPendingTransaction.vue');
const ViewPendingTransport = () => import(/* webpackChunkName: "view-pending-transaction" */ './components/pages/ViewPendingTransport.vue');
const Draft = () => import(/* webpackChunkName: "draft" */ './components/pages/Draft.vue');
const Manifest = () => import(/* webpackChunkName: "manifest" */ './components/pages/Manifest.vue');
const TrackTrace = () => import(/* webpackChunkName: "track-trace" */ './components/pages/TrackTrace.vue');
const ViewProcessed = () => import(/* webpackChunkName: "view-processed" */ './components/pages/ViewProcessed.vue');
const ProfileSettings = () => import(/* webpackChunkName: "profile-settings" */ './components/pages/ProfileSettings.vue');
const PasswordManagement = () => import(/* webpackChunkName: "password-management" */ './components/pages/PasswordManagement.vue');
const TransmittalSlip = () => import(/* webpackChunkName: "transmittal-slip" */ './components/pages/TransmittalSlip.vue');
const GenerateSlip = () => import(/* webpackChunkName: "generate-slip" */ './components/pages/GenerateSlip.vue');
const ViewTransmittalSlip = () => import(/* webpackChunkName: "view-transmittal-slip" */ './components/pages/ViewTransmittalSlip.vue');
const ViewTransmittalSlipBranch = () => import(/* webpackChunkName: "view-transmittal-slip-branch" */ './components/pages/ViewTransmittalSlipBranch.vue');
const CreateTransmittalSlipHubAdmin = () => import(/* webpackChunkName: "create-transmittal-slip-hub-admin" */ './components/pages/CreateTransmittalSlipHubAdmin.vue');
const CreateTransmittalSlipHubAdminByScan = () => import(/* webpackChunkName: "create-transmittal-slip-hub-admin-by-scan" */ './components/pages/CreateTransmittalSlipHubAdminByScan.vue');
const CreateDispatchDeliveryNote = () => import(/* webpackChunkName: "create-dispatch-delivery-note" */ './components/pages/CreateDispatchDeliveryNote.vue');
const ServiceDetails = () => import(/* webpackChunkName: "service-details" */ './components/pages/ServiceDetails.vue');
const ServiceUpdate = () => import(/* webpackChunkName: "service-update" */ './components/pages/ServiceUpdate.vue');
const ServiceConfirm = () => import(/* webpackChunkName: "service-confirm" */ './components/pages/ServiceConfirm.vue');
const ReportExpressRegisteredPost = () => import(/* webpackChunkName: "report-express-registered-post" */ './components/pages/ReportExpressRegisteredPost.vue');
const BulkBooking = () => import(/* webpackChunkName: "bulk-booking" */ './components/pages/BulkBooking.vue');
const BulkBookingUser = () => import(/* webpackChunkName: "bulk-booking-user" */ './components/pages/BulkBookingUser.vue');
const QuickTransportBooking = () => import(/* webpackChunkName: "quick-transport-booking" */ './components/pages/QuickTransportBooking.vue');
const OutboundTransactionReport = () => import(/* webpackChunkName: "outbound-report" */ './components/pages/OutboundReport.vue');
const OutboundInvoiceReport = () => import(/* webpackChunkName: "outbound-invoice-report" */ './components/pages/OutboundInvoiceReport.vue');
const PostalItemInvoiceReport = () => import(/* webpackChunkName: "postal-item-invoice-report" */ './components/pages/PostalItemInvoiceReport.vue');
const ReportPostalItemsSalesReconciliation = () => import(/* webpackChunkName: "outbound-report" */ './components/pages/ReportPostalItemsSalesReconciliation.vue');
const ReportPostalItemSalesTracker = () => import(/* webpackChunkName: "postal-item-sales-tracker-booking" */ './components/pages/ReportPostalItemSalesTracker.vue');
const QuoteAndBook = () => import(/* webpackChunkName: 'quote-and-book' */'./components/pages/QuoteAndBook.vue');
const QuickCourierBooking = () => import(/* webpackChunkName: "quick-courier-booking" */ './components/pages/QuickCourierBooking.vue');
const TransportStatusBooking = () => import(/* webpackChunkName: "transport-status-booking" */ './components/pages/TransportStatusBooking.vue');
const UserManagement = () => import(/* webpackChunkName: "user-management" */ './components/pages/UserManagement.vue');
const GenerateLabel = () => import(/* webpackChunkName: "generate-label" */ './components/pages/GenerateLabel.vue');
const Dispatch = () => import(/* webpackChunkName: "dispatch" */ './components/pages/Dispatch.vue');
const DispatchMessenger = () => import(/* webpackChunkName: "diaptch-messenger" */ './components/pages/DispatchMessenger.vue');
const ModeOfDispatch = () => import(/* webpackChunkName: "mode-of-dispatch" */ './components/pages/masterdata/ModeOfDispatch.vue');
const TypeOfGood = () => import(/* webpackChunkName: "type-of-good" */ './components/pages/masterdata/TypeOfGood.vue');
const Carriers = () => import(/* webpackChunkName: "carriers" */ './components/pages/masterdata/Carriers.vue');
const HolidayCalender = () => import(/* webpackChunkName: "carriers" */ './components/pages/masterdata/HolidayCalender.vue');
const CarrierServices = () => import(/* webpackChunkName: "carrier-services" */ './components/pages/masterdata/CarrierServices.vue');
const Unit = () => import(/* webpackChunkName: "unit" */ './components/pages/masterdata/Unit.vue');
const Division = () => import(/* webpackChunkName: "division" */ './components/pages/masterdata/Division.vue');
const Hub = () => import(/* webpackChunkName: "hub" */ './components/pages/masterdata/Hub.vue');
const Ptaeo = () => import(/* webpackChunkName: "ptaeo" */ './components/pages/masterdata/Ptaeo.vue');
const CostCenter = () => import(/* webpackChunkName: "cost-center" */ './components/pages/masterdata/CostCenter.vue');
const DepartmentLocations = () => import(/* webpackChunkName: "department-locations" */ './components/pages/masterdata/DepartmentLocations.vue');
const MailroomLocations = () => import(/* webpackChunkName: "mailroom-locations" */ './components/pages/masterdata/MailroomLocations.vue');
const EmailTemplate = () => import(/* webpackChunkName: "email-template" */ './components/pages/masterdata/EmailTemplate.vue');
const PostalDocumentType = () => import(/* webpackChunkName: "postal-document-type" */ './components/pages/masterdata/PostalDocumentType.vue');
const CarrierServiceName = () => import(/* webpackChunkName: "carrier-service-name" */ './components/pages/masterdata/CarrierServices.vue');
const TransitTime = () => import(/* webpackChunkName: "transite-time" */ './components/pages/masterdata/TransitTime.vue');
const UserServiceBudget = () => import(/* webpackChunkName: "user-service-budget" */ './components/pages/UserServiceBudget.vue');
const Sidebar = () => import(/* webpackChunkName: "sidebar" */ './components/Sidebar.vue');
const Help = () => import(/* webpackChunkName: "help" */ './components/pages/Help.vue');
const FAQ = () => import(/* webpackChunkName: "faq" */ './components/pages/FAQ.vue');
const Support = () => import(/* webpackChunkName: "support" */ './components/pages/Support.vue');
// const CustomerInvoiceReconciliation = () => import(/* webpackChunkName: "customer-invoice-reconciliation" */ './components/pages/CustomerInvoiceReconciliation.vue');
// const CarrierInvoiceReconciliation = () => import(/* webpackChunkName: "carrier-invoice-reconciliation" */ './components/pages/CarrierInvoiceReconciliation.vue');
const PostalTransactionSummaryReport = () => import(/* webpackChunkName: "postal-transaction-summary-report" */ './components/pages/PostalTransactionSummaryReport.vue');
const ReportPostalServiceRequest = () => import(/* webpackChunkName: "report-postal-service-request" */ './components/pages/ReportPostalServiceRequest.vue');
const ReportPostalServiceIssue = () => import(/* webpackChunkName: "report-postal-service-issue" */ './components/pages/ReportPostalServiceIssue.vue');
const PostalPrepaidService = () => import(/* webpackChunkName: "postal-prepaid-service" */ './components/pages/PostalPrepaidService.vue');
// const InvoiceUpload = () => import(/* webpackChunkName: "invoice-upload" */ './components/pages/InvoiceUpload.vue');
const InvoiceReconciliation = () => import(/* webpackChunkName: "invoice-reconciliation" */ './components/pages/InvoiceReconciliation.vue');
const InvoiceReconciliationProcess = () => import(/* webpackChunkName: "invoice-reconciliation-process" */ './components/pages/InvoiceReconciliationProcess.vue');
// const ReconciliationHistory = () => import(/* webpackChunkName: "reconciliation-history" */ './components/pages/ReconciliationHistory.vue');
const ReportDispatchManagerLogs = () => import(/* webpackChunkName: "report-dispatch-manager" */ './components/pages/ReportDispatchManagerLogs.vue');
const ReportIntegrationGatewayLogs = () => import(/* webpackChunkName: "report-integration-gateway" */ './components/pages/ReportIntegrationGatewayLogs.vue');
const SSOLogin = () => import(/* webpackChunkName: 'sso-login' */ './components/SSOLogin.vue');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
        },
        {
            path: '/sso-login',
            name: 'login',
            component: SSOLogin,
        },
        {
            path: '/verifyotp',
            name: 'verifyotp',
            component: OtpVerify,
        },
        {
            path: '/pages',
            name: 'sidebar',
            component: Sidebar,
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'Encoding/:id?',
                    name: 'Encoding',
                    component: Encoding,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'MailRoom/:id?',
                    name: 'MailRoom',
                    component: MailRoom,
                    meta: {
                        roles: ['hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'AddressBook',
                    name: 'AddressBook',
                    component: AddressBook,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
				{
                    path: 'PersonalAddressBook',
                    name: 'PersonalAddressBook',
                    component: PersonalAddressBook,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin',],
                    },
                },
                {
                    path: 'PostalTransactionSummary',
                    name: 'PostalTransactionSummary',
                    component: PostalTransactionSummary,
                    meta: {
                        roles: ['super_admin', 'hub_admin'],
                    },
                },
                // {
                //     path: 'InvoiceUpload',
                //     name: 'InvoiceUpload',
                //     component: InvoiceUpload,
                //     meta: {
                //         roles: ['hub_admin'],
                //     },
                // },
                {
                    path: 'InvoiceReconciliation',
                    name: 'InvoiceReconciliation',
                    component: InvoiceReconciliation,
                    meta: {
                        roles: ['hub_admin'],
                    },
                },
                {
                    path: 'InvoiceReconciliationProcess/:id?',
                    name: 'InvoiceReconciliationProcess',
                    component: InvoiceReconciliationProcess,
                    meta: {
                        roles: ['hub_admin'],
                    },
                },
                // {
                //     path: 'ReconciliationHistory',
                //     name: 'ReconciliationHistory',
                //     component: ReconciliationHistory,
                //     meta: {
                //         roles: ['hub_admin'],
                //     },
                // },

                // {
                //     path: 'CustomerInvoiceReconciliation',
                //     name: 'CustomerInvoiceReconciliation',
                //     component: CustomerInvoiceReconciliation,
                //     meta: {
                //         roles: ['hub_user', 'hub_admin', 'super_admin'],
                //     },
                // },
                // {
                //     path: 'CarrierInvoiceReconciliation',
                //     name: 'CarrierInvoiceReconciliation',
                //     component: CarrierInvoiceReconciliation,
                //     meta: {
                //         roles: ['hub_user', 'hub_admin', 'super_admin'],
                //     },
                // },

                {
                    path: 'Reports',
                    name: 'Reports',
                    component: Reports,
                    meta: {
                        roles: ['hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportsMailroom',
                    name: 'ReportsMailroom',
                    component: ReportsMailroom,
                    meta: {
                        roles: ['hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'CourierBillingReport',
                    name: 'CourierBillingReport',
                    component: CourierBillingReport,
                    meta: {
                        roles: ['hub_admin'],
                    },
                },                
                {
                    path: 'ReportsDelivery',
                    name: 'ReportsDelivery',
                    component: ReportsDelivery,
                    meta: {
                        roles: ['hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportsDeliveryPercentage',
                    name: 'ReportsDeliveryPercentage',
                    component: ReportsDeliveryPercentage,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'InboundDeliveryReport',
                    name: 'InboundDeliveryReport',
                    component: InboundDeliveryReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'OutboundPostalReport',
                    name: 'OutboundPostalReport',
                    component: OutboundPostalReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'OutboundMarkupReport',
                    name: 'OutboundMarkupReport',
                    component: OutboundMarkupReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportsDeliveryPerformance',
                    name: 'ReportsDeliveryPerformance',
                    component: ReportsDeliveryPerformance,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },

                {
                    path: 'PostalTransactionSummaryReport',
                    name: 'PostalTransactionSummaryReport',
                    component: PostalTransactionSummaryReport,
                    meta: {
                        roles: ['hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportExpressRegisteredPost',
                    name: 'ReportExpressRegisteredPost',
                    component: ReportExpressRegisteredPost,
                    meta: {
                        roles: ['hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportPostalServiceRequest',
                    name: 'ReportPostalServiceRequest',
                    component: ReportPostalServiceRequest,
                    meta: {
                        roles: ['user'],
                    },
                },
                {
                    path: 'OutboundInvoiceReport',
                    name: 'OutboundInvoiceReport',
                    component: OutboundInvoiceReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'PostalItemInvoiceReport',
                    name: 'PostalItemInvoiceReport',
                    component: PostalItemInvoiceReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'OutboundTransactionReport',
                    name: 'OutboundTransactionReport',
                    component: OutboundTransactionReport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportPostalItemsSalesReconciliation',
                    name: 'ReportPostalItemsSalesReconciliation',
                    component: ReportPostalItemsSalesReconciliation,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportPostalServiceIssue',
                    name: 'ReportPostalServiceIssue',
                    component: ReportPostalServiceIssue,
                    meta: {
                        roles: ['hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportPostalItemSalesTracker',
                    name: 'ReportPostalItemSalesTracker',
                    component: ReportPostalItemSalesTracker,
                    meta: {
                        roles: ['hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ViewPending',
                    name: 'ViewPending',
                    component: ViewPending,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                        bookingType: 'Mailroom',
                    },
                },

                {
                    path: 'ViewCourierPending',
                    name: 'ViewCourierPending',
                    component: ViewCourierPending,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                        bookingType: 'Courier',
                    },
                },

                {
                    path: 'ViewEncodePending',
                    name: 'ViewEncodePending',
                    component: ViewEncodePending,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                        bookingType: 'Encode',
                    },
                },
                {
                    path: 'ViewPendingTransport',
                    name: 'ViewPendingTransport',
                    component: ViewPendingTransport,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ViewPendingTransaction',
                    name: 'ViewPendingTransaction',
                    component: ViewPendingTransaction,
                    meta: {
                        roles: ['hub_admin'],
                    },
                },
                {
                    path: 'Draft',
                    name: 'Draft',
                    component: Draft,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'TrackTrace',
                    name: 'TrackTrace',
                    component: TrackTrace,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'ViewProcessed',
                    name: 'ViewProcessed',
                    component: ViewProcessed,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'GenerateLabel',
                    name: 'GenerateLabel',
                    component: GenerateLabel,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ProfileSettings',
                    name: 'ProfileSettings',
                    component: ProfileSettings,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'PasswordManagement',
                    name: 'PasswordManagement',
                    component: PasswordManagement,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'TransmittalSlip',
                    name: 'TransmittalSlip',
                    component: TransmittalSlip,
                },

                {
                    path: 'Dispatch',
                    name: 'Dispatch',
                    component: Dispatch,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'DispatchMessenger',
                    name: 'DispatchMessenger',
                    component: DispatchMessenger,
                    meta: {
                        roles: ['hub_admin'],
                    },
                },
                {
                    path: 'GenerateSlip',
                    name: 'GenerateSlip',
                    component: GenerateSlip,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'ViewTransmittalSlip',
                    name: 'ViewTransmittalSlip',
                    component: ViewTransmittalSlip,
                    meta: {
                        roles: ['hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'ViewTransmittalSlipBranch',
                    name: 'ViewTransmittalSlipBranch',
                    component: ViewTransmittalSlipBranch,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'CreateTransmittalSlipHubAdmin',
                    name: 'CreateTransmittalSlipHubAdmin',
                    component: CreateTransmittalSlipHubAdmin,
                    meta: {
                        roles: ['hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'CreateTransmittalSlipHubAdminByScan',
                    name: 'CreateTransmittalSlipHubAdminByScan',
                    component: CreateTransmittalSlipHubAdminByScan,
                    meta: {
                        roles: ['hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'CreateDispatchDeliveryNote/:type?',
                    name: 'CreateDispatchDeliveryNote',
                    component: CreateDispatchDeliveryNote,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },

                {
                    path: 'Manifest/:type?',
                    name: 'Manifest',
                    component: Manifest,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'ServiceDetails/:id?/:type?',
                    name: 'ServiceDetails',
                    component: ServiceDetails,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ServiceUpdate/:id?/:type?',
                    name: 'ServiceUpdate',
                    component: ServiceUpdate,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ServiceConfirm/:id?/:type?',
                    name: 'ServiceConfirm',
                    component: ServiceConfirm,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'BulkBooking',
                    name: 'BulkBooking',
                    component: BulkBooking,
                    meta: {
                        roles: ['user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'BulkBookingUser',
                    name: 'BulkBookingUser',
                    component: BulkBookingUser,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin'],
                    },
                },
                {
                    path: 'QuickTransportBooking/:id?/:type?',
                    name: 'QuickTransportBooking',
                    component: QuickTransportBooking,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'QuickCourierBooking/:id?',
                    name: 'QuickCourierBooking',
                    component: QuickCourierBooking,
                    meta: {
                        roles: ['user', 'hub_user'],
                    },
                },
                {
                    path: 'QuoteAndBook',
                    name: 'QuoteAndBook',
                    component: QuoteAndBook,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'TransportStatusBooking',
                    name: 'TransportStatusBooking',
                    component: TransportStatusBooking,
                    meta: {
                        roles: ['hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'UserManagement',
                    name: 'UserManagement',
                    component: UserManagement,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Help',
                    name: 'Help',
                    component: Help,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },

                {
                    path: 'FAQ',
                    name: 'FAQ',
                    component: FAQ,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'Support',
                    name: 'Support',
                    component: Support,
                    meta: {
                        roles: [
                            'user',
                            'hub_user',
                            'hub_admin',
                            'customer_service_user',
                            'super_admin',
                        ],
                    },
                },
                {
                    path: 'ModeOfDispatch',
                    name: 'ModeOfDispatch',
                    component: ModeOfDispatch,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'TypeOfGood',
                    name: 'TypeOfGood',
                    component: TypeOfGood,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Carriers',
                    name: 'Carriers',
                    component: Carriers,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'HolidayCalender',
                    name: 'HolidayCalender',
                    component: HolidayCalender,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'CarrierServices',
                    name: 'CarrierServices',
                    component: CarrierServices,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Unit',
                    name: 'Unit',
                    component: Unit,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Division',
                    name: 'Division',
                    component: Division,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Hub',
                    name: 'Hub',
                    component: Hub,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'Ptaeo',
                    name: 'Ptaeo',
                    component: Ptaeo,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'CostCenter',
                    name: 'CostCenter',
                    component: CostCenter,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'DepartmentLocations',
                    name: 'DepartmentLocations',
                    component: DepartmentLocations,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'MailroomLocations',
                    name: 'MailroomLocations',
                    component: MailroomLocations,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'EmailTemplate',
                    name: 'EmailTemplate',
                    component: EmailTemplate,
                    meta: {
                        roles: ['super_admin'],
                    },
                },

                {
                    path: 'PostalDocumentType',
                    name: 'PostalDocumentType',
                    component: PostalDocumentType,
                    meta: {
                        roles: ['super_admin'],
                    },
                },

                {
                    path: 'CarrierServiceName',
                    name: 'CarrierServiceName',
                    component: CarrierServices,
                    meta: {
                        roles: ['super_admin'],
                    },
                },

                {
                    path: 'TransitTime',
                    name: 'TransitTime',
                    component: TransitTime,
                    meta: {
                        roles: ['super_admin'],
                    },
                },
                {
                    path: 'UserServiceBudget',
                    name: 'UserServiceBudget',
                    component: UserServiceBudget,
                    meta: {
                        roles: ['user', 'super_admin', 'hub_admin'],
                    },
                },
                {
                    path: 'PostalPrepaidService',
                    name: 'PostalPrepaidService',
                    component: PostalPrepaidService,
                    meta: {
                        roles: ['user', 'super_admin', 'hub_admin'],
                    },
                },
                {
                    path: 'ViewPostalServices',
                    name: 'ViewPostalServices',
                    component: ViewPostalServices,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportDispatchManagerLogs',
                    name: 'ReportDispatchManagerLogs',
                    component: ReportDispatchManagerLogs,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
                {
                    path: 'ReportIntegrationGatewayLogs',
                    name: 'ReportIntegrationGatewayLogs',
                    component: ReportIntegrationGatewayLogs,
                    meta: {
                        roles: ['user', 'hub_user', 'hub_admin', 'super_admin'],
                    },
                },
            ],
        },
    ],
});

import Toast from './mixins/toast.js';
import Auth from './mixins/auth.js';
import http from './http.js';

let previousRoute = null;

router.beforeEach((to, from, next) => {
    previousRoute = from;

    if (to.fullPath.includes('pages') || to.fullPath.includes('json')) {
        try {
            if (!to.meta.roles.includes(Auth().user_type)) {
                http.post('api/auth/logout');
                throw 'Unauthorized access';
            }
        } catch (e) {
            localStorage.clear();
            Toast.error('You must login first');
            router.push('/');
        }
    }
    next();
});

export function getPreviousRoute() {
    return previousRoute;
}

export default router;
